import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector, Vector as VectorSource, XYZ } from 'ol/source';
import { Projection, fromLonLat } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import TileGrid from 'ol/tilegrid/TileGrid';
import { DragRotateAndZoom } from 'ol/interaction';
import { Control } from 'ol/control';
import Axios from 'axios';

const TileMapInfo = {
    "x1": -135110.156,
    "x2": 205684.1,
    "y1": -197653.719,
    "y2": 143140.531,
    "minZoom": 0,
	"maxZoom": 8,
	'MAX_X': 65536, //rozmiar kafelka * 256, czyli 256 * 256
	'MAX_Y': 65536,
};

const game_coord_to_pixels = (xx, yy) => {
	const xtot = TileMapInfo.x2 - TileMapInfo.x1;
	const ytot = TileMapInfo.y2 - TileMapInfo.y1;
	const xrel = (xx - TileMapInfo.x1) / xtot;
	const yrel = (yy - TileMapInfo.y1) / ytot;
	return [xrel * TileMapInfo.MAX_X, TileMapInfo.MAX_Y - (yrel * TileMapInfo.MAX_Y)];
};


export default function Mapa(props){
	const mapRef = useRef(null);
	const [miasta, setMiasta] = useState([]);

	useEffect(() => {
		const dostanMiasta = async () => {
			const dane = await fetch("/MAPAETS/Cities.json");
			const parseDane = await dane.json();
			setMiasta(parseDane);
		};

		if(!miasta.length){
			dostanMiasta();
			return;
		}

		const projection = new Projection({
			code: "Funbit",
			units: "pixels",
			extent: [0, 0, TileMapInfo.MAX_X, TileMapInfo.MAX_Y],
			worldExtent: [0, 0, TileMapInfo.MAX_X, TileMapInfo.MAX_Y]
		});

		const customTile = new TileGrid({
			extent: [0, 0, TileMapInfo.MAX_X, TileMapInfo.MAX_Y],
			minZoom: 0,
			origin: [0, TileMapInfo.MAX_Y],
			tileSize: [256, 256],
			resolutions: (() => {
				const resolutions = [];
				for (let z = 0; z <= 8; ++z) {
				resolutions[z] = Math.pow(2, 8 - z);
				}
				return resolutions;
			})(),
		});

		const mapaTekst = (res, nazwa) => {
			// console.log(res, nazwa);
			const scale = Math.min(1, Math.max(0, 1.0 / Math.log2(res + 1) - 0.015));
			const tekst = nazwa;
			const fillT = new Fill();
			fillT.setColor("#ff8a0066");
			const strokeT = new Stroke();
			strokeT.setColor("#00000066");
			strokeT.setWidth(2);

			return [new Style({
				text: new Text({
					text: tekst,
					font: 'bold 34pt "Montserrat"',
					textAlign: 'center',
					fill: fillT,
					stroke: strokeT,
					scale: scale,
					offsetY: 96 * scale
				})
			})]
		};

		const map = new Map({
			target: mapRef.current,
			layers: [
				new TileLayer({
					// MAPA GŁÓWNA
					source: new XYZ({
					tileSize: [256, 256],
					maxZoom: 8,
					minZoom: 0,
					wrapX: false,
					projection: projection,
					url: `/MAPAETS/Tiles/{z}/{x}/{y}.png`,
					tilePixelRatio: 1,
					tileGrid: customTile
					}),
				}),
				new VectorLayer({
					source: new Vector({
						features: miasta.map((city) => {
							const koordy = game_coord_to_pixels(city.X, city.Y);
							const tempFeature = new Feature(city);
							tempFeature.setGeometry(new Point(koordy));
							tempFeature.setStyle((feature, resolution) => mapaTekst(resolution, city.LocalizedNames.pl_pl || city.Name));
							return tempFeature;
						})
					}),
				})
			],
			view: new View({
				projection: projection,
				extent: [0, 0, TileMapInfo.MAX_X, TileMapInfo.MAX_Y],
				//center: ol.proj.transform([37.41, 8.82], 'EPSG:4326', 'EPSG:3857'),
				center: [TileMapInfo.MAX_X/2, TileMapInfo.MAX_Y/2],
				minZoom: 0,
				maxZoom: 8,
				zoom: 4
			}),
			// interactions: [
			// 	new DragRotateAndZoom(),
			// ],
		});
	
		return () => {
			map.setTarget(null);
		};
	}, [miasta]);
  
	return <div ref={mapRef} style={{ width: '100%', height: '100vh' }} />;
};